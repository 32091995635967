import React, { useState,  useEffect } from "react";
import {
  Divider,
  Row,
  Col,
  Option,
  Upload,
  Select,
  Button,
  message,
  notification,
  Checkbox,
} from "antd";
import Boton_peticion_handler from "../../../../components/Admin/boton_peticion_handler/boton_peticion_handler";
import { CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { asociarFacturaUsuario } from "../../../../api/series";
import { getAccessTokenApi } from "../../../../api/auth";
import DesplegableMultiple from "../../../../components/DesplegableMultiple";
import Dragger from "antd/lib/upload/Dragger";
// const { Dragger } = Upload;
import { getInmueblesApi } from "../../../../api/inmueble";
import { enviarCorreoInquilino, enviarCorreoPropietarioInmobiliario } from "../../../../api/user";

export default function Factura_inquilino_subir({correo_envio, fecha_entrada, fecha_salida}) {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inmuebles, setInmuebles] = useState([]);
  const [enviar_correo, setenviar_correo] = useState(false);
  const [correo_propietario_enviar_factura, setcorreo_propietario_enviar_factura] = useState(correo_envio);
  const accesToken = getAccessTokenApi();


  const handleFileChange = (info) => {
    let fileList = [...info.fileList];

    // Limitar la cantidad de archivos a uno
    fileList = fileList.slice(-1);

    // Filtrar archivos inválidos
    fileList = fileList.filter((file) => {
      if (file.size > 1024 * 1024 * 5) {
        message.error(
          `${file.name} es demasiado grande. El tamaño máximo es 5MB.`
        );
        return false;
      }
      return true;
    });

    setFileList(fileList);
  };

  const handleUpload = () => {
    
    if (fileList.length === 0) {
      message.error("Por favor, selecciona un archivo.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file.originFileObj);
    });


      if(true){
        formData.append("subject", "Turistea - ¡Ya tienes disponible la factura asociada a tu estancia!");
        formData.append("body_email", "Esta información es confidencial, si no deberías haber recibido esta información haznoslo saber en facturas@turisteasuites.es o comotucasa.info@gmail.com.");
        formData.append("correo", correo_envio);
        formData.append("fecha_entrada", fecha_entrada);
        
        formData.append("fecha_salida", fecha_salida);
        
        
        enviarCorreoInquilino(formData)
          .then((response) => {
            if (response.message) {
              message.success("Correo enviado");
              setFileList([]); // Limpiar la lista de archivos después de la carga
              
            } else {
              message.error("Error al enviar el correo");
            }
          })
          .catch((error) => {
            console.error("Error al enviar el correo:", error);
            message.error("Error al enviar el correo."+error);
          });
      }

   
  };
  const { Option } = Select;
  return (
    <div>


      <Row >
            Correo Inquilino: 
            <b>{ correo_envio}</b>
        </Row>  
       
      <Divider />
      <Dragger
        fileList={fileList}
        onChange={handleFileChange}
        multiple={false}
        customRequest={() => {}}
        showUploadList={{ showRemoveIcon: true }}
        beforeUpload={() => false}
        iconRender={(file, defaultIcon) =>
          file.status === "done" ? <CheckCircleOutlined /> : defaultIcon
        }
      >
        <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
      </Dragger>
      {fileList.length > 0 ? (
        <Boton_peticion_handler
          isLoading={loading}
          cuerpo="Enviar"
          handler={handleUpload}
        />
      ) : (
        <Button disabled={true}>Enviar</Button>
      )}
    </div>
  );
}
