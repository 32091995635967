import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../../api/auth";
import { eliminarFacturaAsociada, obtenerFacturasPlataformaFechas, verFacturas, getSeries } from "../../../../api/series";
import { Button, List, DatePicker, Card, Select, Modal, Typography, Row, Col, Divider, Collapse } from "antd";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { getInmueblesApi } from "../../../../api/inmueble";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import FacturasProductos from "../../../../components/FacturasProductos/FacturasProductos";

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

export default function FacturasPlataformaFechas() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedInmueble, setSelectedInmueble] = useState(null);
  const [inmuebles, setInmuebles] = useState([]);
  const [facturas, setFacturas] = useState([]);
  const [seriesMap, setSeriesMap] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");

  const accessToken = getAccessTokenApi();

  useEffect(() => {
    async function fetchInmuebles() {
      const response = await getInmueblesApi(accessToken, 1, true);
      if (response.Pisos) setInmuebles(response.Pisos);
    }

    async function fetchSeries() {
      const response = await getSeries(accessToken);
      if (response.series) {
        const seriesMapping = response.series.reduce((acc, serie) => {
          acc[serie._id] = serie.nombre_serie;
          return acc;
        }, {});
        setSeriesMap(seriesMapping);
      }
    }

    fetchInmuebles();
    fetchSeries();
  }, [accessToken]);

  const handleDateChange = (dates) => setDateRange(dates);

  const handleInmuebleChange = (value) => setSelectedInmueble(value);

  const handleSearch = async () => {
    const [startDate, endDate] = dateRange || [];
    const formattedStartDate = startDate ? startDate.format("YYYY-MM-DD") : null;
    const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : null;

    const response = await obtenerFacturasPlataformaFechas(
      accessToken,
      selectedInmueble,
      formattedStartDate,
      formattedEndDate
    );

    if (response.error) {
      setFacturas([]);
    } else if (response && response.archivos) {
      setFacturas(response.archivos);
    }
  };

  const handleDeletePdf = async (pdfId) => {
    try {
      await eliminarFacturaAsociada(accessToken, selectedInmueble, pdfId);
      handleSearch();
    } catch (error) {
      console.error("Error al eliminar archivo PDF:", error);
    }
  };

  const handleViewPdf = async (pdfId) => {
    try {
      const pdfUrl = await verFacturas(accessToken, selectedInmueble, pdfId);
      setCurrentPdfUrl(pdfUrl);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error al visualizar el archivo PDF:", error);
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <Select
          placeholder="Selecciona un inmueble"
          style={{ width: 200, marginRight: 10 }}
          onChange={handleInmuebleChange}
        >
          {inmuebles.map((inmueble) => (
            <Select.Option key={inmueble._id} value={inmueble._id}>
              {inmueble.name}
            </Select.Option>
          ))}
        </Select>

        <RangePicker format="YYYY-MM-DD" onChange={handleDateChange} />
        <Button type="primary" onClick={handleSearch} style={{ marginLeft: 10 }}>
          Buscar
        </Button>
        Total de facturas en el periodo: {facturas.length}

      </div>

      <List
        itemLayout="vertical"
        dataSource={facturas}
        renderItem={(factura) => (
          <List.Item>
            <Card
              title={`Factura Nº: ${factura.numero_factura} | Serie: ${seriesMap[factura.serie_facturacion]  || "Serie no encontrada"} | Fecha emitida: ${factura.fecha_emision_factura} `}
              bordered
              extra={
                <div>
                  {jwt_decode(accessToken).role === "Administrador" && (
                    <Button
                      type="primary"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeletePdf(factura._id)}
                      style={{ marginRight: 8 }}
                    >
                      Eliminar
                    </Button>
                  )}
                  <Button
                    type="primary"
                    icon={<EyeOutlined />}
                    onClick={() => handleViewPdf(factura._id)}
                  >
                    Ver
                  </Button>
                </div>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Title level={5}>Datos del Emisor</Title>
                  <Text><strong>Nombre:</strong> {factura.data_emisor?.nombre}</Text><br />
                  <Text><strong>Dirección:</strong> {factura.data_emisor?.direccion_emisor}</Text><br />
                  <Text><strong>NIF:</strong> {factura.data_emisor?.numero_identificacion_fiscal}</Text>
                </Col>
                <Col span={12}>
                  <Title level={5}>Datos del Receptor</Title>
                  <Text><strong>Nombre:</strong> {factura.data_receptor.nombre}</Text><br />
                  <Text><strong>Dirección:</strong> {factura.data_receptor.direccion_emisor}</Text><br />
                  <Text><strong>NIF:</strong> {factura.data_receptor.numero_identificacion_fiscal}</Text>
                </Col>
              </Row>
              <Divider />

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Title level={5}>Conceptos Facturados {factura.conceptos_facturar.reduce((acc, item) => acc + parseFloat(item.total), 0).toFixed(2)} € </Title>
                
                  <Collapse>
                  <Collapse.Panel>
                  {factura.conceptos_facturar.map((concepto, index) => (
                    <>
                    <Card key={index} type="inner" style={{ marginBottom: "10px" }}>
                      <Text><strong>Descripción:</strong> {concepto.descripcion_item}</Text><br />
                      <Text><strong>Cantidad:</strong> {concepto.cantidad}</Text><br />
                      <Text><strong>Precio Unitario:</strong> {concepto.precio_ud} €</Text><br />
                      <Text><strong>Impuesto (%):</strong> {concepto.tax_percentage}</Text><br />
                      <Text><strong>Impuesto (€):</strong> {concepto.tax_number} €</Text><br />
                      <Text><strong>Total:</strong> {concepto.total} €</Text>
                    </Card>
                     <Collapse>
                     <Collapse.Panel>
                       <FacturasProductos
                                 irnr={factura?.irnr}
                                 retencion={factura?.retencion}
                                 serie_nombre =  {seriesMap[factura.serie_facturacion]}
                                 numero_factura={factura?.numero_factura ?? ""}
                                 nombre_lomo={""                                 }
                                 proforma={false}
                                 nombre_emisor={factura?.data_emisor?.nombre ?? ""}
                                 direccionEmisor={factura?.data_emisor?.direccion_emisor ?? ""}
                                 nifEmisor={factura?.data_emisor?.numero_identificacion_fiscal ?? ""}
                                 nombreReceptor={factura?.data_receptor.nombre ?? ""}
                                 direccionReceptor={factura?.data_receptor.direccion_emisor ?? ""}
                                 nifReceptor={factura?.data_receptor.numero_identificacion_fiscal ?? ""}
                                 elementosFactura={factura?.conceptos_facturar ?? ""}
                                 fecha_creacion={factura?.fecha_emision_factura ?? ""}
                                 datos_facturacion_administrador={factura?.datosFacturacionAdministrador ?? []}
                                 datos_mes={factura?.conceptos_facturar ?? []}
                                //  inquilinos={usersActive ?? []}
                                 datos_facturacion={factura?.inmueble_facturacion ?? {}}
                       
                               />
                     </Collapse.Panel>
                   </Collapse>
                   </>
                  ))}
                  </Collapse.Panel>
                  </Collapse>
                 
                </Col>
              </Row>

              <Divider />
              
            </Card>
          </List.Item>
        )}
      />

      <Modal
        title="Visualización de PDF"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="80%"
      >
        <iframe
          src={currentPdfUrl}
          style={{ width: "100%", height: "800px" }}
          frameBorder="0"
        />
      </Modal>
    </div>
  );
}
