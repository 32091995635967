import React, { useState } from 'react';
import { Button, Modal, Input, Form, InputNumber, notification, Select } from 'antd';
import { getSeriesInmueble } from '../../../../api/series';
import { getAccessTokenApi } from '../../../../api/auth';
import FacturasClientesConcretos from '../../Modulo_Facturas/FacturasClientesConcretos';

const { Option } = Select;

const Boton_factura_CC = ({ record, monthQuery, yearQuery }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [taxPercentage, setTaxPercentage] = useState(0.1); // Por defecto 10%
 
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields(); // Validar el formulario

      const response = await getSeriesInmueble(getAccessTokenApi(), record._id);
      const serie_a_enviar = response?.message?.serie_empresa_gestora_a_inquilino_pide_factura || "";

      if (!serie_a_enviar) {
        notification['error']({
          message: 'Error: no se ha podido obtener la serie.',
        });
        return;
      }

      // Generar el query string con los valores ingresados
      const queryString = new URLSearchParams({
        nombreEmisor: 'DPD RENTAL MANAGEMENT SL',
        nifEmisor: 'B72879711',
        direccionEmisor: 'CAMINO FUENTE DE LA JUNQUERA 142',
        cantidad: '1',
        precioUd: Number(Number(values.precioUd)/( Number(taxPercentage)+1)).toFixed(2),
        descripcionItem: `Factura Alquiler a ${record.nombre} Mes ${monthQuery + 1}/${yearQuery}`,
        nombreReceptor: values.nombreReceptor,
        direccionReceptor: values.direccionReceptor,
        nifReceptor: values.nifReceptor,
        taxPercentage: "10%", // Enviar el porcentaje como 10 o 0
        taxNumber:  Number(Number(values.precioUd)-Number(values.precioUd)/( Number(taxPercentage)+1)).toFixed(2),
        inmueble: record._id,
        serie_id: serie_a_enviar,
        retencion: values.retencion || 0, // Puedes personalizar este valor si es necesario
        irnr: values.irnr??false,
        fecha_entrada : record.fecha_entrada??"",
        fecha_salida: record.fecha_salida ??"",
      }).toString();
      console.log("Record", record)
      const url = `/admin/facturas?${queryString}`;
      window.open(url, '_blank'); // Abrir en nueva pestaña
      form.resetFields()
      setIsModalVisible(false); // Cerrar el modal
    } catch (error) {
      notification['error']({
        message: 'Error al generar la factura.',
        description: error.message,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Función para actualizar el taxNumber al cambiar el precio o el porcentaje
  const updateTaxNumber = (precioUd) => {
    // setTaxNumber(precioUd * taxPercentage);
  };

  return (
    <>
      <Button style={{ background: 'yellow' }} onClick={showModal}>
      <b>{record.nombre}</b> - Fact. Subarriendo CC  este mes {record.total_facturar_cc}€
      </Button>

      <Modal
        title="Datos de Facturación"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="100%"
        style={{
          top: 100,
          maxWidth: "90vw",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        bodyStyle={{
          padding: "20px",
          height: "80vh",
          overflowY: "scroll",
        }}
      >
        {/* <Form form={form} layout="vertical">
          
          <Form.Item
            label="Nombre Receptor"
            name="nombreReceptor"
            rules={[{ required: true, message: 'Por favor ingresa el nombre del receptor' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Dirección Receptor"
            name="direccionReceptor"
            rules={[{ required: true, message: 'Por favor ingresa la dirección del receptor' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="NIF Receptor"
            name="nifReceptor"
            rules={[{ required: true, message: 'Por favor ingresa el NIF del receptor' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Importe Factura con IVA"
            name="precioUd"
            rules={[{ required: true, message: 'Por favor ingresa el precio' }]}
          >
            <InputNumber 
              min={0} 
              style={{ width: '100%' }} 
              onChange={(value) => updateTaxNumber(value)} // Actualiza el impuesto al cambiar el precio
            />
          </Form.Item>

              

          <Form.Item
            label="Retención"
            name="retencion"
            initialValue={0}
          >
            <InputNumber min={0} max={1} step={0.01} style={{ width: '100%' }} />
          </Form.Item>
        </Form> */}
         <FacturasClientesConcretos inmueble={record._id} />
      </Modal>
    </>
  );
};

export default Boton_factura_CC;
